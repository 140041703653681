@import "@constants/constants";
.menu{
  position: absolute;
  display: flex;
  right: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 30%;
  height: 60%;
  z-index: 4;
  @include tablet {
    &:before {

      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(40, 40, 40, 0.5);
      clip-path: polygon(
                      0% 100%,
                      0% 0%,
                      100% 0%,
                      100% 0%,
                      100% 100%,
                      100% 100%,
                      0 0%,
                      0 100%
      );
    }
  }
  @include desktop {
    height: get-vh(165px);
  }
  .content{
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: get-vh(17.5px) 0px;
    @include desktop {
      height: auto;
      width: 200px;
      & > *:first-child{
        display: none !important;
      }
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
      padding: get-vh(17.5px) 0;
      margin-right: 55px;
    }
  }
}
