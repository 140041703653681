@import "@constants/constants";
.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: get-vh(180px);
  @include desktop{
    margin: get-vh(155px) auto;
    width: 40%;
  }
  .help {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .blockHelp {
      display: flex;
      width: 150px;
      flex-direction: column;
      justify-content: space-between;
      align-self: center;
      background-image: $backgroundImage;
      box-shadow: $boxShadow;
      border-radius: 5px;
      h3{
        font-weight: 100;
        margin-top: 0;
      }
      h2{
        margin: 10px 0;
        font-weight: 100;
      }
    }
  }

  .info {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    width: 100%;
    background-image: $backgroundImage;
    box-shadow: $boxShadow;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px 10px;
    .blockInfo {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-self: center;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
      h3{
        margin: 0;
        &:first-child{
          margin-right: 10px;
          text-transform: uppercase;
        }
        &:last-child{
          font-weight: 100;
        }
      }
    }
  }

  .contact {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    background-image: $backgroundImage;
    box-shadow: $boxShadow;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 10px;
    h3{
      margin-right: 25px;
      text-transform: uppercase;
    }
    .contactName{
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      width: auto;
      margin-right: 25px;
      font-weight: 100;
    }
  }

  .screens {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15px;
    .screen {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: flex-start;
      margin-bottom: 15px;
      width: calc(50% - 7.5px);
      cursor: pointer;
      background-image: $backgroundImage;
      box-shadow: $boxShadow;
      border-radius: 5px;
      padding: 10px;
      h3 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0;
        margin: 0;
      }

      .action {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-top: 10px;
        @include desktop{
          margin-top: 5px;
        }
        h3{
          margin: 0;
          font-weight: 100;
          margin-left: 15px;
        }
      }
    }
  }
}
