@import "@constants/constants";
.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  margin-top: get-vh(180px);
  @include desktop {
    margin: get-vh(155px) auto;
    width: 40%;
  }
  .middle {
    margin-top: 15px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    width: 100%;
    @include desktop {
      width: 85%;
    }
    background-image: $backgroundImage;
    box-shadow: $boxShadow;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 15px 10px;

    .contact {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-self: center;
      margin-top: 15px;
      &:first-child{
        margin: 0;
      }
      h3{
        margin: 0;
        &:first-child{
          margin-right: 10px;
          text-transform: uppercase;
        }
        &:last-child{
          font-weight: 100;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .search{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 0 25px;
    @include desktop {
      padding: 0 0;
    }
    box-sizing: border-box;
  }
  .site{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    background-image: $backgroundImage;
    box-shadow: $boxShadow;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    @include desktop {
      width: 85%;
      padding: 5px;
    }
    &:first-child{
      margin-top: 0;
    }
    h3{
      margin: 0;
      margin-left: 15px;
    }
    .install{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      h3{
        margin: 0;
        font-weight: 100;
      }
      h4{
        margin: 0;
        font-weight: 100;
      }
    }
  }
}
