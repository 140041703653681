@import "@constants/constants";
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: get-vh(180px);
  h1{
    margin: 0;
    justify-self: start;
  }
  .middle{
    margin: auto 0;
    background-image: $backgroundImage;
    box-shadow: $boxShadow;
    padding: 40px 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      &:first-child{
        margin-bottom: 15px;
      }
    }
  }
}
