@import "@constants/constants";
.modal{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  .content{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: get-vw(300px);
    height: get_vh(125px);
    border: 2px dashed $secondary;
    background-image: $backgroundImage;
    box-shadow: $boxShadow;
    color: $primary;
    padding: get-vh(10px) get-vw(10px);
    span:first-child {
      font-size: get-vw(16px);
    }
    span:last-child {
      font-size: get-vw(14px);
    }
    .controls{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      span {
        font-size: get-vw(26px);
        margin-right: get-vw(10px);
      }
    }
  }
}