@import "@constants/constants";
.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: get-vh(180px);
  @include desktop{
    margin: get-vh(155px) auto;
    width: 65%;
  }
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include desktop{
      flex-direction: row;
    }
    .helpBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      @include desktop{
        margin-right: 40px;
        width: calc(50% - 40px);
      }
      .help {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .blockHelp {
          display: flex;
          width: 150px;
          flex-direction: column;
          justify-content: space-between;
          align-self: center;
          background-image: $backgroundImage;
          box-shadow: $boxShadow;
          border-radius: 5px;

          h3 {
            font-weight: 100;
            margin-top: 0;
          }

          h2 {
            margin: 10px 0;
            font-weight: 100;
          }
        }
      }

      .info {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: center;
        width: 100%;
        background-image: $backgroundImage;
        box-shadow: $boxShadow;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 15px 10px;

        .blockInfo {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: flex-start;
          align-self: center;
          margin-top: 15px;

          &:first-child {
            margin: 0;
          }

          h3 {
            margin: 0;

            &:first-child {
              margin-right: 10px;
              text-transform: uppercase;
            }

            &:last-child {
              font-weight: 100;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .action {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-top: 15px;
          width: 100%;

          h3 {
            margin: 0;
            text-transform: uppercase;
            font-weight: 100;
            margin-left: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .description {
      margin-top: 15px;
      background-image: $backgroundImage;
      box-shadow: $boxShadow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      @include desktop{
        margin-top: 0;
        margin-left: 40px;
        width: calc(50% - 40px);
      }
      h3 {
        margin: 0;
        font-weight: bold;

        &:nth-child(even) {
          margin-left: 15px;
          margin-top: 10px;
          margin-bottom: 15px;
          font-weight: 100;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:nth-child(odd) {
          text-transform: uppercase;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
  .middle {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    background-image: $backgroundImage;
    box-shadow: $boxShadow;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px 0;
    .middleBlock {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: calc(50% - 10px);
      height: auto;
      margin: 5px;
      @include desktop{
        width: calc(25% - 10px);
      }
      .image {
        width: 100%;
        height: auto;
        background-color: black;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
