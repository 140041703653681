@import "@constants/constants";

.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  margin-top: get-vh(180px);
  @include desktop {
    margin: get-vh(155px) auto;
    width: 50%;
  }
  .content {
    h1{
      margin-bottom: 25px;
    }
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    padding: 0 50px;
  }
}
