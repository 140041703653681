@import "@constants/constants";

.page {
  height: 100%;
  width: 100%;
  @include desktop {
    margin: 0 auto;
    width: 45%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .content {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    box-sizing: border-box;
    margin-top: get-vh(180px);
    @include desktop{
      margin-top: get-vh(155px);
    }
    padding: 0 50px;
    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 60px;
      @include desktop{
        margin-bottom: 75px;
      }
      & > * {
        margin: 0px 0;
      }
    }
    .submit{
      @include desktop {
        width: 60%;
      }
    }
  }
}
