@import "@constants/constants";
.content{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: get-vh(180px);
  @include desktop{
    margin: get-vh(155px) auto;
    width: 45%;
  }
  h1{
    margin-bottom: 10px;
  }
  h2{
    margin-top: 0;
    font-weight: 100;
  }
  .form{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 50px;
  }
}
