@import "@constants/constants";

.app{
  background: url("./assets/background elements/mobile_bg.jpg") no-repeat;
  @include desktop {
    background: url("./assets/background elements/landscape_bg.jpg") no-repeat;
    background-size: 100% 100%;
  }
  background-size: 100% 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  h1{
    font-size: get-vh(26px);
    @include desktop{
      font-size: get-vh(22px);
    }
    font-weight: bold;
    font-family: $font-family;
    color: $primary;
    text-align: center;
    margin: 0 0 20px 0;
    text-transform: uppercase;
  }
  h2{
    font-size: 22px;
    @include desktop{
      font-size: get-vh(20px);
    }
    font-weight: bold;
    font-family: $font-family;
    color: $primary;
    text-align: center;
  }
  h3{
    font-size: 20px;
    @include desktop{
      font-size: get-vh(15px);
    }
    font-weight: bold;
    font-family: $font-family;
    color: $primary;
    text-align: center;
  }
  h4{
    font-size: 14px;
    font-weight: bold;
    font-family: $font-family;
    color: $primary;
    text-align: center;
  }
  .scanner{
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    background-color: black;
    .scannerCloseIcon{
      position: fixed;
      padding: 20px;
      color: #C4C4C4;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }
}

