@import "@constants/constants";
.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  .form{
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    & > * {
      margin-bottom: 20px;
    }
  }
  .delimiter{
    font-size: 14px;
    color: $third;
    font-weight: bold;
    text-align: center;
    margin-top: 42px;
    margin-bottom: 62px;
    @media only screen and (orientation: landscape) and (max-width: 1024px) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }
}
