$desktop-width: 1224px;

@function get-vw($target) {
  $vw-context: (540*.01) * 1px;
  @return $target;
  //@return ($target/$vw-context) * 1vw;
}

@function get-vh($target) {
  $vh-context: (960*.01) * 1px;
  @return $target;
  //@return ($target/$vh-context) * 1vh;
}

@mixin desktop {
  @media only screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: calc(#{$desktop-width} - 1px)) {
    @content;
  }
}

@font-face {
  font-family: 'Metropolis';
  src: url("../assets/fonts/Metropolis-Regular.otf"), url("../assets/fonts/Metropolis-SemiBold.otf")
}

$bg: #FFFFFF;
$bg2: #40A7FF00;
$primary: #ffffff;
$secondary: #009bdb;
$third: #7B7B7B;
$success: #2BAC18;

$backgroundImage: -webkit-linear-gradient( 90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.2) 100%);
$boxShadow: 0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33);
$font-family: "Metropolis";
