@import "@constants/constants";
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: get-vh(180px);
  @include desktop{
    margin: get-vh(155px) auto;
    width: 60%;
  }
  h1 {
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 0;
    font-weight: 100;
  }
  .info {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    @include desktop{
      flex-direction: row;
    }
    .description {
      background-image: $backgroundImage;
      box-shadow: $boxShadow;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      @include desktop{
        margin-right: 40px;
        width: calc(50% - 40px);
      }
      h3 {
        margin: 0;

        &:nth-child(even) {
          margin-left: 15px;
          margin-top: 10px;
          margin-bottom: 15px;
          font-weight: 100;
        }

        &:nth-child(odd) {
          text-transform: uppercase;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .middle {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-image: $backgroundImage;
      box-shadow: $boxShadow;
      width: 100%;
      margin-top: 20px;
      border-radius: 5px;
      padding: 10px 0;
      @include desktop{
        margin-top: 0;
        margin-left: 40px;
        width: calc(50% - 40px);
        flex-wrap: wrap;
        padding: 0;
      }
      .middleBlock {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        height: 100%;
        padding: 5px;
        @include desktop{
          margin: 5px;
          width: calc(50% - 10px);
          height: calc(50% - 10px);
          padding: 0;
        }
        .image {
          width: 100%;
          height: 100%;
          @include desktop{
            width: 100%;
            height: 100%;
          }
          background-color: black;
          img {
            object-fit: fill;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
