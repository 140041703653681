@import "@constants/constants";
.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: get-vh(180px);
  @include desktop {
    margin: get-vh(155px) auto;
    width: 45%;
  }
  h1{
    margin-bottom: 15px;
  }
  h2{
    font-weight: 100;
    margin: 0;
  }
  .form{
    margin-top: 25px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 50px;
  }
}
