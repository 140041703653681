@import "@constants/constants";

.page{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(40,40,40,0.5);
    clip-path: polygon(
                    0% 29%,
                    50% 0%,
                    100% 0%,
                    100% 0%,
                    100% 100%,
                    100% 100%,
                    0 100%,
                    0 100%
    );
  }
  @include desktop {
    &:before {
      clip-path: polygon(
                      0% 290px,
                      400px 0%,
                      100% 0%,
                      100% 0%,
                      100% 100%,
                      100% 100%,
                      0 100%,
                      0 100%
      );
    }
  }
  .content{
    position: inherit;
    box-sizing: border-box;
    padding: get-vh(20px) get-vw(20px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .footer{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: get-vh(20px) get-vw(70px);
    @include desktop{
      width: 33%;
      margin-bottom: 30px;
    }
  }
}
