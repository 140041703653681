@import "@constants/constants";
.content{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: get-vh(180px);
  box-sizing: border-box;
  padding: 0 get-vw(41px);
  .helpContent{
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: get-vw(300px);
    height: get-vh(250px);
    .arrow {
      z-index: 2;
      margin-left: auto;
      margin-right: get-vw(20px);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid white;
    }
    .image {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      box-shadow: $boxShadow;
      .closeIcon{
        margin-top: 5px;
        margin-left: auto;
        margin-right: get-vw(21px);
        font-size: get-vw(18px);
      }
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  @include desktop{
    margin: get-vh(155px) auto;
    width: 45%;
  }
  .code{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: get-vh(10px);
    .codeHelp{
      align-self: flex-end;
      margin: get-vh(-10px) 8px 10px;
    }
  }
}
