@import "@constants/constants";
.content{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: get-vh(180px);
  @include desktop{
    margin: get-vh(155px) auto;
    width: 70%;
  }
  h1, h2{
    margin-bottom: 10px;
  }
  .title{
    font-weight: 100;
    width: auto;
    font-family: $font-family;
    color: $primary;
    font-size: 20px;
    text-align: center;
  }
  .images {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    @include desktop{
      height: 100%;
      flex-direction: row;
    }
    .current {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      min-height: 160px;
      margin-top: 25px;

      .imageBlock {
        background-image: $backgroundImage;
        box-shadow: $boxShadow;
        border: 2px dashed $primary;
        width: 196px;
        height: 156px;
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;

        img {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
      }
    }

    .example {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      justify-content: flex-start;
      align-items: center;
      margin-top: 25px;
      @include desktop {
        justify-content: center;
      }
      .exampleImage {
        width: 200px;
        height: 160px;
      }
    }
  }
}
