@import "@constants/constants";

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: get-vh(180px);
  @include desktop{
    margin: get-vh(155px) auto;
    width: 65%;
  }
  h1{
    font-weight: 100;
  }
  .form{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: get-vh(20px) get-vw(41px);
    & > * {
      margin-bottom: 10px;
    }
    @include desktop{
      flex-direction: row;
      & > * {
        margin: 0 40px;
      }
    }
  }
}
