.icon{
  //transform: scale(calc(960px / 100%));
  width: 21px;
  height: 26px;
  display: flex;
  align-items: center;
  .image{
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
  }
}
