.content{
  width: 100%;
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .actions{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    .action{
      box-sizing: border-box;
      width: 50%;
      min-height: 82px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
      cursor: pointer;
      h2{
        text-align: start;
      }
    }
  }
}
