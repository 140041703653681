@import "@constants/constants";
.header{
  position: absolute;
  box-sizing: border-box;
  height: get-vh(200px);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 get-vw(10px);
  @include desktop {
    height: get-vh(165px);
    padding: 0 get-vw(55px);
  }
  background: rgba(40,40,40,0.5);
  z-index: 3;
  //backdrop-filter: blur(5px);
  clip-path:
    polygon(
      0% 0%,                 /* top left */
      0% 0%,                 /* top left */
      100% 0%,    /* top right */
      100% 0,               /* top right */
      100% 100%,  /* bottom right */
      100% 100%,  /* bottom right */
      100% 100%,               /* bottom left */
      0 45%      /* bottom left */
    );
  @include desktop {
    clip-path:
        polygon(
            0% 0%,                 /* top left */
            0% 0%,                 /* top left */
            100% 0%,    /* top right */
            100% 0,               /* top right */
            100% 100%,  /* bottom right */
            100% 100%,  /* bottom right */
            100% 100%,               /* bottom left */
            0 38%      /* bottom left */
        );
  }
  .content {
    position: relative;
    margin-top: get-vh(17.5px);
    width: 100%;
    height: auto;
    min-height: get-vh(35px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include desktop{
      margin-top: get-vh(23px);
    }
    .logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        height: get-vh(25px);
        @include desktop{
          height: get-vh(23px);
        }
        width: 100%;
      }
    }
  }
}
